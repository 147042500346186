import React from "react";
import "../assets/css/style.scss";

import SpecificDetails from "./specificAnswer.js";

export default function Fertiledetails({ pageContext, slug }) {
  console.log("PAGE CONETEXT", pageContext);
  return (
    <div className="Fertileedge">
      <SpecificDetails singleBlog={pageContext} slug={slug} />
    </div>
  );
}
