export function getTextFromHTML(html) {
    if (typeof document !== `undefined`) {
        var e = document.createElement('textarea');
        e.innerHTML = html;
        // handle case of empty input
        let text = e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;

        var tmp = document.createElement('div');
        tmp.innerHTML = text;
        return tmp.textContent || tmp.innerText
    }
    else {
        return html
    }
}

export const convertHtmlToText = function (input) {
    var ret = input.replace(/&gt;/g, ">");
    ret = ret.replace(/&lt;/g, "<");
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, "&");
    ret = ret.replace(/&nbsp;/g, "");

    var stripedHtml = ret.replace(/<[^>]+>/g, '');

    return stripedHtml;
};