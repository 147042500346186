import React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import DynamicBlog from "../components/Fertile/details/detaildyynamic.js";
import ReactHtmlParser from "react-html-parser";
import { convertHtmlToText } from '../utils/utils'

export default function blogfertile({ pageContext }) {
  console.log("PAGE CONTEXT IN BLOG POST", pageContext);
  const converthtml = function (input) {
    var ret = input.replace(/&gt;/g, ">");
    ret = ret.replace(/&lt;/g, "<");
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, "&");
    ret = ret.replace(/&nbsp;/g, "");

    return ret;
  };
  var slug = pageContext.product.node.slug;
  pageContext = pageContext.product.node.metadata;

  var renderhtml = ReactHtmlParser(
    converthtml(pageContext.detail_description.replace(/srcset/g, "nonsrcsdet"))
  );
  renderhtml = renderhtml.slice(0, 150) + "...";
  return (
    <Layout>
      <SEO
        image={pageContext.detail_vew_image.url}
        title={
          pageContext.blog_title.length > 50
            ? pageContext.blog_title.slice(0, 50)
            : pageContext.blog_title
        }
        description={
          (pageContext.meta_description && pageContext.meta_description !== "") ?
            pageContext.meta_description :
            pageContext.detail_description.length > 150
              ? convertHtmlToText(pageContext.detail_description).slice(0, 150) + "..."
              : convertHtmlToText(pageContext.detail_description)
        }
        keywords={pageContext.seo_keyword ? pageContext.seo_keyword.split(',').map(keyword => keyword.trim()) : []}
      />
      <DynamicBlog pageContext={pageContext} slug={slug} />
    </Layout>
  );
}
