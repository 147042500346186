import React, { useRef } from "react";
import { Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import ShareLink from "react-facebook-share-link";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { TwitterShareButton } from "react-share";

export default function SpecificDetails({ singleBlog, slug }) {
  const staticimgad = useRef();

  const converthtml = function (input) {
    var ret = input.replace(/&gt;/g, ">");
    ret = ret.replace(/&lt;/g, "<");
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, "&");
    ret = ret.replace(/&nbsp;/g, "");

    return ret;
  };

  const singletwitter = useRef();

  let advertisement = singleBlog?.advertisement?.metadata

  return (
    <div className="SpecificDetails">
      <div className="content position-relative">
        {advertisement &&
          <div className="sticky-feature">
            <Col md={12} lg={4}>
              <div className="" ref={staticimgad}>
                <div className="addblock addsecond">
                  <h3 style={advertisement.color && { color: advertisement.color }}>{advertisement.title}</h3>
                  <img className="main-img" src={advertisement.image.url} />
                  <p>
                    {
                      advertisement.description.length >= 150 ?
                        advertisement.description.slice(0, 150) + "..." :
                        advertisement.description
                    }
                  </p>
                  <a
                    href={advertisement.link}
                    target="_blank"
                    style={advertisement.color && { backgroundColor: advertisement.color }}
                  >
                    Learn More
                    </a>
                </div>
              </div>
            </Col>
          </div>
        }
        <Col md={12} lg={8}>
          <h1>{singleBlog.blog_title}</h1>

          <div className="user">
            <div>
              <div>
                <h4>{singleBlog.blog_category}</h4>
                <p>
                  {`BY ${singleBlog.author_details.bog_author_name}, ${singleBlog.posted_date}`}{" "}
                </p>
              </div>
              <div className="share-media"></div>
            </div>
            <div className="social-content ">
              <div className="socialcount">
                <div className="box twitter ">
                  <TwitterShareButton
                    url={
                      typeof window !== "undefined" &&
                      window.location.host +
                      "/fertile-edge-magazine/" +
                      slug.trim()
                    }
                  >
                    <FaTwitter /> Tweet
                  </TwitterShareButton>
                </div>

                <div className="box facebook">
                  <ShareLink
                    link={
                      typeof window !== "undefined" &&
                      window.location.host +
                      "/fertile-edge-magazine/" +
                      slug.trim()
                    }
                  >
                    {(link) => (
                      <a
                        onClick={() => {
                          typeof window !== "undefined" &&
                            window.open(
                              link,
                              "_blank",
                              "resizable,height=600,width=800"
                            );
                        }}
                        className="facebook-share"
                      >
                        {" "}
                        <FaFacebook /> Share
                      </a>
                    )}
                  </ShareLink>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="main thumbnail">
            <img src={singleBlog.detail_vew_image.url} className="" alt="Image for post" />
          </div>
          <div className="social-content check formater">
            <div className="socialcount" id="social-content">
              <div className="box twitter">
                <TwitterShareButton
                  url={
                    typeof window !== "undefined" &&
                    window.location.host +
                    "/fertile-edge-magazine/" +
                    slug.trim()
                  }
                >
                  <FaTwitter />
                </TwitterShareButton>
              </div>
              <div className="box facebook">
                <ShareLink
                  link={
                    typeof window !== "undefined" &&
                    window.location.host +
                    "/fertile-edge-magazine/" +
                    slug.trim()
                  }
                >
                  {(link) => (
                    <a
                      onClick={() => {
                        typeof window !== "undefined" &&
                          window.open(
                            link,
                            "_blank",
                            "resizable,height=600,width=800"
                          );
                      }}
                      className="facebook-share"
                    >
                      {" "}
                      <FaFacebook />
                    </a>
                  )}
                </ShareLink>{" "}
              </div>
            </div>
            <div>
              {ReactHtmlParser(
                converthtml(
                  singleBlog.detail_description.replace(/srcset/g, "nonsrcsdet")
                )
              )}
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
}
